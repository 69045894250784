.visit {
  /* border-bottom: 2px solid var(--orange); */
  color: var(--blue);
  font-weight: 700;
  position: relative;
  font-size: 18px;
}
.visit::before {
  display: block;
  content: "";
  transition: all 2s;
  width: 35px;
  height: 4px;
  background: var(--blue);
  position: absolute;
  bottom: 0;
}
.li {
  font-weight: bold;
  color: var(--orange);
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.li:hover {
  /* border-bottom: 2px solid var(--orange); */
  font-weight: bold;

  color: black;
}

.enrollButton {
  border: 2px solid var(--blue);
}

.enrollLink {
  color: var(--blue);
  text-decoration: none;
}
.enrollButton:hover {
  background-color: var(--orange);
}
.enrollButton:hover .enrollLink {
  color: white;
}
