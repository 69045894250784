.h5 {
  background-color: var(--blue);
  color: white;
  transition: width 2s;
}

.button {
  background-color: white;
  border: 1px solid var(--blue);

  font-weight: bold;
}
.button:hover {
  background-color: var(--blue);
  color: white;
  font-weight: bold;
}
.coursecard {
  transition: all 2s;
}
.courseCard:hover {
  transform: translate(0px, 10px);
}
.coursecard:hover h5 {
  width: 300px;
}

.li {
  color: var(--blue);
  text-decoration: none;
}
.button:hover .li {
  color: white;
}
